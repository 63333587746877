<template>
	<div class="bodys">
		<transition name="el-fade-in-linear">
			<div class="contentsa" v-if="loginshow==0">
				<div class="yglogo"></div>
				<div class="contentsq">
					<h6 class="titles">用户登录</h6>
					<div class="nameicon">
						<transition name="el-fade-in-linear">
							<span v-show="userimg" class="nameicon1 ">

              				</span>
						</transition>
						<input style="color: #343434;text-align: center;" @focus="focusa()" @blur="blura()" type="text" v-model.trim="username" AUTOCOMPLETE="off" class="username" placeholder="请输入账号" />
					</div>
					<div class="passicon">
						<transition name="el-fade-in-linear">
							<span v-show="passimg" class=" pswdicon1">

              				</span>
						</transition>
						<input style="color: #343434;text-align: center;" @focus="focusb()" @blur="blurb()" type="password" placeholder="请输入密码" autocomplete="new-password" class="username userpass" v-model.trim="passwords" />
					</div>
					<!-- <div class="passicon"> -->
						<!--<transition name="el-fade-in-linear">
							<span v-show="codeimg" class=" pswdicon1">

              				</span>
						</transition>-->
						<!--<img style="position: absolute;right: 0px;top: 3px;width: 60px;" :src="vcodeimg" alt="加載中..." class="imgcode" @click="getImgcheckCode()">-->
						<!-- <div style="position: absolute;right: 20px;top: 3px;width: 60px;" @click="getImgcheckCode()">
							<identify :identifyCode="identifyCode"></identify>

						</div> -->

						<!-- <input style="color: #343434;text-align: center;" @focus="focuscode()" @blur="blurcode()" type="text" placeholder="请输入验证码" autocomplete="new-password" class="username userpass" v-model.trim="Vcode" /> -->
					<!-- </div> -->
					<div class="btnst" v-if="logings">
						<button @click="login()">登 录</button>

					</div>
					<div class="btnst btnsta" v-if="logings == false">
						<div class="el-icon-loading" style="font-size: 27px;color:#9bf2fa;"></div>
					</div>

					<p class="userregistration ugis" @click="adduser">用户注册</p>
					<p class="userregistration" @click="forgetpassword">忘记密码</p>

				</div>
			</div>
		</transition>
		<!--忘记密码-->
		<transition name="el-fade-in-linear">
			<div class="contentsa" v-if="loginshow==1">
				<div class="yglogo"></div>

				<div class="contentsq">
					<h6 class="titles">找回密码</h6>
					<div class="nameicon nameicontop">
						<!--<transition name="el-fade-in-linear">
							<span v-show="userimg" class="nameicon1 ">

              				</span>
						</transition>-->
						<!--<p class="sendcode" @click="SendCode">发送验证码</p>-->
						<span class="sendcode" @click="SendCode" v-if="getcodeState">获取验证码</span>
						<span class="sendcode" v-if="!getcodeState">{{vcodeDateNum}}s</span>
						<input style="color: #343434;text-align: center;" type="text" v-model.trim="lmobile" AUTOCOMPLETE="off" class="username" placeholder="请输入手机号" />
					</div>
					<div class="nameicon nameicontop">
						<!--<transition name="el-fade-in-linear">
							<span v-show="userimg" class="nameicon1 ">
              				</span>
						</transition>-->
						<input style="color: #343434;text-align: center;" @focus="lcodefocusa()" @blur="lcodeblura()" type="text" v-model.trim="lcode" AUTOCOMPLETE="off" class="username" placeholder="请输入验证码" />
					</div>
					<div class="nameicon nameicontop">
						<!--<transition name="el-fade-in-linear">
							<span v-show="userimg" class="nameicon1 ">

              				</span>
						</transition>-->
						<input style="color: #343434;text-align: center;" type="password" placeholder="请输入新密码" autocomplete="new-password" class="username userpass" v-model.trim="lpassword" />

					</div>
					<div class="oldpass">
						<p v-if="verificationcode==true" class="adduserBtn adddeteRmine oldps" @click="mobilecode()" style="float: right;">确 定</p>
						<p v-if="verificationcode==false" class="adduserBtn oldps" style="float: right;">确 定</p>
						<p class="adduserBtn oldps" @click="cancel" style="float: left;">关 闭</p>
					</div>

				</div>
			</div>

		</transition>

		<transition name="el-zoom-in-top">
			<!--用户注册  -->
			<div class="Adduser " v-if="loginshow==2">
				<h6 class="usertit">用户注册</h6>
				<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom">
					<el-row>
						<el-col :span="24">
							<el-form-item type="type" label="手机号" prop="mobile">
								<el-input :disabled="disableds" v-model="ruleForm.mobile" placeholder="手机号" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item type="type" label="用户名" prop="userName">
								<el-input :disabled="disableds" autocomplete='off' v-model="ruleForm.userName" placeholder="用户名" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item type="type" label="用户昵称" prop="nickName">
								<el-input :disabled="disableds" autocomplete='off' v-model="ruleForm.nickName" placeholder="用户昵称" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item type="type" label="登录密码" prop="passWord">
								<el-input :disabled="disableds" autocomplete='off' show-password v-model="ruleForm.passWord" placeholder="登录密码" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item type="type" label="确认密码" prop="repassword">
								<el-input :disabled="disableds" autocomplete='off' show-password v-model="ruleForm.repassword" placeholder="确认密码" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item type="type" label="邮箱" prop="email">
								<el-input :disabled="disableds" v-model="ruleForm.email" placeholder="邮箱" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item type="type" label="管理员头像" prop="UserHead" class="frombr">

								<!--:action="UploadFile()"  :data="Upfliedata" :on-error="fileerror" :on-success='filesuccess'-->
								<el-upload accept=".jpg,.png" ref="upload" v-if="!disableds" action="" :on-preview="handlePictureCardPreview" :auto-upload="false" list-type="picture-card" :on-change="filechange" :on-remove="handleRemove">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
								</el-upload>
								<el-dialog v-dialogDrag title="预览" append-to-body :visible.sync="imgDialogVisible">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<p class="adduserBtn adddeteRmine" @click="deteRmine('ruleForm')">确 定</p>
							<p class="adduserBtn" @click="cancel">关 闭</p>
						</el-col>

					</el-row>

				</el-form>

			</div>
		</transition>

	</div>
</template>
<script>
	import { Login,} from '@/api/api.js';
	//import JsEncrypt from "jsencrypt";
	import md5 from 'js-md5';
	import axios from 'axios'


	export default {
		components: {
		},
		props: {},
		data() {
			let validatePass2 = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入密码'))
					// password 是表单上绑定的字段
				} else if(value !== this.ruleForm.passWord) {
					callback(new Error('两次输入密码不一致!'))
				} else {
					callback()
				}
			}
			return {
				identifyCodes: '1234567890',
				identifyCode: '',
				Vcode: "",
				vcodeimg: '',
				codetimer: null,
				getcodeState: true,
				vcodeDateNum: 60,
				verificationcode: false,
				lmobile: '',
				lcode: '',
				lpassword: '',
				loginshow: 0,
				username: 'admin',
				passwords: '123456',
				dlshow: false,
				timer: null,
				userimg: false,
				passimg: false,
				logings: true,
				imgDialogVisible: false,
				ruleForm: {
					mobile: '',
					userName: '',
					nickName: '',
					passWord: '',
					repassword: "",
					email: '',

				},
				rules: {
					mobile: [{
							required: true,
							message: '请输入必选项',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '请输入有效的手机号',
							trigger: 'blur'
						},
					],
					userName: [{
						required: true,
						message: '请输入必选项',
						trigger: 'blur'
					}, ],

					nickName: [{
						required: true,
						message: '请输入必选项',
						trigger: 'blur'
					}, ],
					passWord: [{
						required: true,
						message: "请输入必填项",
						trigger: "blur"
					}, ],
					repassword: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请输入必选项',
						trigger: 'blur'
					}, ],

				},
				disableds: false,
				fileList: [],
				dialogImageUrl: '',

			};
		},

		created() {
			//this.getimgCode()
		},
		beforeDestroy() {
			clearInterval(this.timer);
			clearInterval(this.codetimer);
			document.onkeydown = '';
			this.timer = null;
			this.codetimer = null;
		},
		destroyed() {
			window.removeEventListener('keydown', this.keyDown, false); //销毁回车事件，如果不销毁，在其他页面敲回车也会执行回车登录操作。
		},
		mounted() {
			this.makeCode(this.identifyCodes, 4)
			window.addEventListener('keydown', this.keyDown); //监听用户回车事件
			var _self = this;

			this.timer = setTimeout(function() {
				_self.dlshow = true;
			}, 600)

		},
		methods: {
			keyDown(e) {
				var _self = this;
				if(e.keyCode == 13) {
					if(this.ruleForm.usename != '' && this.ruleForm.password != '') {
						_self.login();
					}
				}
			},

			randomNum(min, max) {
				max = max + 1
				return Math.floor(Math.random() * (max - min) + min)
			},
			makeCode(data, len) {
				for(let i = 0; i < len; i++) {
					this.identifyCode += data[this.randomNum(0, data.length - 1)]
				}

			},

			focuscode() {},
			blurcode() {},

			getimgCode() {

				/*let timestamp = Date.now();
				axios.defaults.withCredentials = true;
				axios.get(VUE_WEB_URL.baseUrl + '/webadmin/adminuser/captcha?tiem=' + timestamp, {
					responseType: 'arraybuffer',
					xhrFields: {
						withCredentials: true
					},
				}).then(response => {
					return 'data:image/png;base64,' + btoa(
						new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
					);
				}).then(data => {
					this.vcodeimg = data
				})*/

			},
			getImgcheckCode() {
				//this.getimgCode()

				this.identifyCode = ''
				this.makeCode(this.identifyCodes, 4)

			},
			mobilecode() {
				let params = {
					mobile: this.lmobile,
					password: this.lpassword,
				}
				retrievepassword(params).then((res) => {
					if(res.errorCode == 0) {
						this.$message({
							message: '修改成功，请重新登录！',
							type: 'success'
						});
						this.loginshow = 0;
						this.verificationcode = false

					} else {
						this.$message.error(res.msg);
						this.verificationcode = false

					}

				}).catch((error) => {
					this.verificationcode = false
					this.$message.error('验证码输入有误！');
				})
			},

			lcodefocusa() {},
			lcodeblura() {

				if(this.lcode != '' && this.lmobile != '') {
					let params = {
						mobile: this.lmobile,
						code: this.lcode
					}
					passwordcheck(params).then((res) => {
						if(res.errorCode == 0) {
							this.verificationcode = true

						} else {
							this.$message.error(res.msg);
							this.verificationcode = false

						}

					}).catch((error) => {
						this.verificationcode = false

						this.$message.error('验证码输入有误！');
					})
				}

			},
			SendCode() {
				if(this.lmobile != '') {
					let params = {
						mobile: this.lmobile
					}
					passwordsendcode(params).then((res) => {
						if(res.errorCode == 0) {

						} else {
							this.$message.error(res.msg);
						}

					}).catch((error) => {
						this.$message.error('验证码发送失败！');
					})

					const TIME_COUNT = 60;
					if(!this.codetimer) {
						this.vcodeDateNum = TIME_COUNT;
						this.getcodeState = false;
						this.codetimer = setInterval(() => {
							if(this.vcodeDateNum > 0 && this.vcodeDateNum <= TIME_COUNT) {
								this.vcodeDateNum--;
							} else {
								this.getcodeState = true;
								clearInterval(this.codetimer);
								this.codetimer = null;
							}
						}, 1000)
					}
				} else {
					this.$message.error('请输入手机号！');

				}

			},
			adduser() {
				this.loginshow = 2;
			},
			forgetpassword() {
				this.loginshow = 1;
			},
			deteRmine(fromName) {
				this.$refs[fromName].validate(valid => {
					if(valid) {
						if(this.dialogState != 'edit') {

							let params = {
								file: this.fileList[0],
								mobile: this.ruleForm.mobile,
								userName: this.ruleForm.userName,
								passWord: this.ruleForm.passWord,
								email: this.ruleForm.email,
								nickName: this.ruleForm.nickName,
							}


						} else {}

					} else {
						return false
					}

				})

			},
			cancel() {
				this.loginshow = 0;

			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.imgDialogVisible = true;
			},
			handleRemove(file, fileList) { // 删除文件
				this.hideUpload = false;
				this.fileList = []
			},
			filechange(file, list) {
				if(file.raw.name.split('.')[1] == 'png' || file.raw.name.split('.')[1] == 'jpg') {
					this.fileList = [file.raw]
					if(list.length > 1) {
						list.splice(0, 1);
					}
				} else {
					this.$message.error('上传头像图片只能是 png/jpg 格式!');
					this.$refs.upload.clearFiles()
					return;
				}
			},

			login() {

				// if(this.Vcode == this.identifyCode) {
					let params = {
            client_id: 'web_client_1',
            scope: 'select',
            grant_type: 'password',
            client_secret: 'Y29kZS1zdGFydGVyLmJjcnlwdC5iYXNlNjQK',
            username: '17719943151',
            password: 'employtest@password',
            autoLogin: 'true',
            type: 'account',
					}


					Login(params).then((res) => {
            console.log(res)
						if(res.status == 200) {
                console.log(res.data.accessToken)
              	localStorage.setItem('Token', res.data.accessToken);
                localStorage.setItem('Userinformation', JSON.stringify(res.data.user))

                console.log(res.data.user.username)
                this.$store.commit('nickname', res.data.user.username)
              
                this.$router.replace('/indexpage');


						} else {
							/*this.$message.error(res.data);*/

							this.$message.error(res.message);

						}

					})
				/* } else {
					this.$message.error('验证码不正确');
					this.Vcode = ''
					this.identifyCode = ''
					this.makeCode(this.identifyCodes, 4)

				} */

			},
			focusa() {
				this.userimg = false;
			},
			blura() {
				if(this.username != "") {
					this.userimg = true;
				} else {

				}

			},
			focusb() {
				this.passimg = false;
			},
			blurb() {
				if(this.passwords != "") {
					this.passimg = true;
				}

			},

		},

	}
</script>

<style scoped="scoped">
	.oldpass {
		width: 80%;
		margin: 0 auto;
	}

	::v-deep .el-dialog {
		border-radius: 8px;
	}

	.oldps {
		margin-top: 40px;
	}

	.sendcode {
		position: absolute;
		right: 0px;
		font-size: 14px;
		color: #16b1c5;
		margin-top: 7px;
		cursor: pointer;
	}

	.ugis {
		margin-right: 10% !important;
	}

	.Adduser {
		position: absolute;
		right: 0px;
		top: 100px;
		right: 15%;
		bottom: 100px;
		margin: auto;
		background: #fff;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
		padding: 10px;
		overflow: auto;
		width: 420px;
		/* left: 0px; */
	}

	.adduserBtn {
		float: right;
		width: 100px !important;
		margin-left: 15px;
		line-height: 30px;
		border-radius: 15px;
		border: 1px solid #999;
		font-size: 14px;
		color: #999;
		text-align: center;
		cursor: pointer;
	}

	.adddeteRmine {
		line-height: 32px;
		border: none;
		background: #16b1c5;
		color: #fff !important;
	}

	.usertit {
		line-height: 30px;
		font-size: 18px;
		color: #333;
		text-align: center;
		font-weight: bold;
	}

	.userregistration {
		line-height: 25px;
		font-size: 14px;
		float: right;
		margin-right: 15px;
		color: #16b1c5;
		margin-top: 10px;
		cursor: pointer;
	}

	.btnsta {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background: #16b1c5;
		border: none;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		color: #fff;
		border-radius: 4px;
	}

	#xuanzhun {
		-webkit-transition-property: -webkit-transform;
		-webkit-transition-duration: 1s;
		-moz-transition-property: -moz-transform;
		-moz-transition-duration: 1s;
		-webkit-animation: rotate 1s linear infinite;
		-moz-animation: rotate 1s linear infinite;
		-o-animation: rotate 1s linear infinite;
		animation: rotate 1s linear infinite;
		background: #ccc;
	}

	@-webkit-keyframes rotate {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@-moz-keyframes rotate {
		from {
			-moz-transform: rotate(0deg);
		}
		to {
			-moz-transform: rotate(359deg);
		}
	}

	@-o-keyframes rotate {
		from {
			-o-transform: rotate(0deg);
		}
		to {
			-o-transform: rotate(359deg);
		}
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}

	.bodys {
		width: 100%;
		height: 100vh;
		background: url(../assets/denglu.jpg) no-repeat center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.contentsa {
		width: 420px;
		height: 500px;
		position: absolute;
		top: 0px;
		right: 15%;
		bottom: 0px;
		margin: auto;
	}

	.contentsq {
		width: 420px;
		height: 402px;
		background: #fff;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	}

	.titles {
		width: 100%;
		height: 100px;
		line-height: 100px;
		color: #333333;
		font-size: 24px;
		font-weight: 600;
		text-align: center;
	}

	.nameicon {
		width: 80%;
		height: 40px;
		margin-left: 10%;
		position: relative;
		/*border-image-source: url();*/
		border-bottom: 1px solid #ccc;
	}

	.nameicontop {
		margin-top: 20px;
	}

	.passicon {
		width: 80%;
		height: 40px;
		margin-left: 10%;
		position: relative;
		margin-top: 20px;
		border-bottom: 1px solid #ccc;
	}

	.nameicon1 {
		position: absolute;
		right: 1%;
		top: 5px;
		color: #ccc;
		z-index: 1;
		font-size: 20px;
		line-height: 40px;
		width: 30px;
		height: 30px;
		display: block;
	}

	.pswdicon1 {
		position: absolute;
		right: 1%;
		top: 5px;
		color: #ccc;
		z-index: 1;
		font-size: 20px;
		line-height: 40px;
		width: 30px;
		height: 30px;
		display: block;
	}

	.username {
		width: calc(100% - 40px);
		height: 40px;
		border: none;
		font-size: 14px;
		border-bottom: 1px solid #16b1c5;
		margin: 0 auto;
		display: block;
	}

	.yglogo {
		width: 420px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.username input {
		padding-left: 40px;
	}

	.el-input__inner {}

	.btnst {
		margin-top: 40px;
		width: 80%;
		margin-left: 10%;
	}

	.btnst button {
		width: 100%;
		background: #16b1c5;
		border: none;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		color: #fff;
		border-radius: 4px;
	}

	.el-button {
		width: 100%;
		background: #1abc9c;
		border: none;
	}

	.tisi {
		margin-top: 20px;
		width: 80%;
		margin-left: 10%;
		display: none;
	}

	.tisi p {
		font-size: 14px;
		color: #f00;
	}

	.compImg {
		width: 100%;
		float: left;
		display: block;
	}

	.titnames {
		float: left;
		font-size: 46px;
		color: #fbfbfc;
		letter-spacing: 5px;
		line-height: 50px;
		margin-left: 15px;
		font-family: FZDBS;
		transform: scale(1, 1.4);
		-ms-transform: scale(1, 1.4);
		-webkit-transform: scale(1, 1.4);
		-moz-transform: scale(1, 1.4);
		-o-transform: scale(1, 1.4);
		margin-top: 12px;
	}

	@font-face {
		font-family: FZDBS;
		src: url('../icon/FZDBSGBK.ttf')
	}
</style>
